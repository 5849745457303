<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap13Types"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 13: Types" image-name="types.jpg" image-alt="Types"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a type?</p></li>
<li><p>What are predeclared types?</p></li>
<li><p>What are composite types?</p></li>
<li><p>What is a type literal.</p></li>
<li><p>What is a type struct?</p></li>
<li><p>What are embedded fields?</p></li>
<li><p>How to create a type.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Type</p></li>
<li><p>Value set</p></li>
<li><p>Predeclared types</p></li>
<li><p>Type struct</p></li>
<li><p>Composite types</p></li>
<li><p>Fields</p></li>
<li><p>Embedded fields</p></li>
</ul>
<div id="what-is-a-type" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> What is a type? <a href="#what-is-a-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>“A type determines a <strong>set of values</strong> together with operations and methods specific to those values.”<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>. Let’s decompose this definition :</p>
<ul>
<li><p>A set of values.…</p>
<ul>
<li><p>A variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">uint32</code></span> can contain all values from 0 to 4.294.967.295. Those 4+ million values are the set of values allowed by this type.</p></li>
<li><p>the string “Go is Great” is not an <span v-highlightjs><code class="go" v-pre style="display: inline">uint32</code></span>, this value does not belong to the set of values allowed by this type</p></li>
</ul></li>
<li><p>... with <strong>operation</strong> and <strong>methods</strong> specific to those values. Operations and methods are capabilities that are shipped with values of a type.</p>
<ul>
<li><p>Types have a set of operations and methods that we can apply to values of those types.</p></li>
<li><p>Go has predeclared types, but you can also create your types. We call those types custom types.</p></li>
<li><p>You can define methods that are attached to a type.</p></li>
<li><p>For instance, a Booking type can have a method to compute the total price to pay by the customer.</p></li>
<li><p><strong>NOTE</strong> : we will cover them in a dedicated method</p></li>
</ul></li>
</ul>
<div id="predeclared-types" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Predeclared types <a href="#predeclared-types"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Go has several predeclared types. Those types are part of the Go core; you do not need to declare them to use them. We can classify them into three categories :</p>
<ul>
<li><p>Boolean type</p>
<ul>
<li>bool</li>
</ul></li>
<li><p>String type</p>
<ul>
<li>string</li>
</ul></li>
<li><p>numeric types</p>
<ul>
<li><p>uint, uint8, uint32, uint64</p></li>
<li><p>int, int8, int32, int64</p></li>
<li><p>float, float32, float64</p></li>
<li><p>complex64, complex128</p></li>
</ul></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >var rooms uint8 = 130
var hotelName string = &quot;New Golang Hotel&quot;
var vacancies bool</code></pre>
<div id="composite-type" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Composite type <a href="#composite-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the previous section,its we have seen that we can create a variable of a basic type.</p>
<p>You can use those basic types to construct <strong><u>composite types.</u></strong></p>
<ul>
<li><p>arrays</p></li>
<li><p>pointers</p></li>
<li><p>functions</p></li>
<li><p>slices</p></li>
<li><p>maps channels</p></li>
<li><p>struct</p></li>
<li><p>interfaces</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// types/composite/main.go
package main

import &quot;fmt&quot;

func main() {
    // array constructed with the basic type uint8
    var arr [3]uint8

    // pointer constructed with the basic type uint8
    var myPointer *uint8

    // function  constructed with the basic type string
    var nameDisplayer func(name, firstname string) string

    // slices constructed with the basic type uint8
    var roomNumbers []uint8

    // maps constructed with the basic types uint8 and string
    var score map[string]uint8

    // channel constructed with the basic type bool
    var received chan&lt;- bool

    // struct, interface
    // ... see next sections
    fmt.Println(arr, myPointer, nameDisplayer, roomNumbers, score, received)
}</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">[3]uint8, *uint8, func(name, firstname string) string,...</code></span> are called <strong><u>type literals</u>.</strong> Composite types are constructed with type literals</p>
<div id="struct-type" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> Struct type <a href="#struct-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>A struct type is a composite type</p>
<pre v-highlightjs><code class="go" v-pre >struct {
    Name     string
    Capacity uint8
    Rooms    uint8
    Smoking  bool
}</code></pre>
<figure>
<b-img :src="require('@/assets/images/struct_anatomy.png')" alt="Struct fields decomposition[fig:Struct-fields-decomposition]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Struct fields decomposition<span id="fig:Struct-fields-decomposition" label="fig:Struct-fields-decomposition">[fig:Struct-fields-decomposition]</span></figcaption>
</figure>
<p>A struct is composed of <strong>fields</strong>. Fields can :</p>
<ol type="1">
<li><p><strong>Explicitly</strong> specified: in this case, the field has a name and a type (in the previous examples all fields are explicit</p></li>
<li><p><strong>Implicitly</strong> specified: in this case, we call those fields <strong>embedded fields</strong> (see next section)</p></li>
</ol>
<div id="how-to-create-new-types-type-definition" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> How to create new types: type definition <a href="#how-to-create-new-types-type-definition"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="new-types-based-on-predeclared-types" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> New types based on predeclared types <a href="#new-types-based-on-predeclared-types"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>We can declare new types based on existing, predeclared types.</p>
<pre v-highlightjs><code class="go" v-pre >// a new type Firstname
// underlying type : string
type Firstname string

// a new type Currency
// underlying type is string
type Currency string

// a new type VATRate
// underlying type is float64
type VATRate float64</code></pre>
<p>Here we create a type from another type. This other type is called the <strong>underlying type</strong>.</p>
<pre v-highlightjs><code class="go" v-pre >type Country string</code></pre>
<ul>
<li><p>We create a new type <span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span></p>
<ul>
<li><p>The type name is <span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span> (identifier)</p></li>
<li><p>The underlying type of <span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span> is <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span>.</p></li>
</ul></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/type_defintion.png')" alt="Type definition"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Type definition</figcaption>
</figure>
<div id="new-types-based-on-composite-types" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> New types based on composite types <a href="#new-types-based-on-composite-types"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You can also declare a new type with an underlying composite type.</p>
<pre v-highlightjs><code class="go" v-pre >// new type &quot;ExchangeRate&quot;
// underlying type is map[string]float64
// map[string]float64 is a type litteral
type ExchangeRate map[string]float64


// new type &quot;Birthdate&quot;
// underlying type : time.Time (type Time from the time package)
type Birthdate time.Time


// new type &quot;Hotel&quot;
// underlying type : struct
type Hotel struct {
    Name     string
    Capacity uint8
    Rooms    uint8
    Smoking  bool
}

// new type &quot;Country&quot;
// underlying type : struct
type Country struct {
    Name        string
    CapitalCity string
}</code></pre>
<div id="type-struct-variable-creation" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Type struct variable creation <a href="#type-struct-variable-creation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="with-fields-names" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> With fields names <a href="#with-fields-names"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >france := Country{
    Name:        &quot;France&quot;,
    CapitalCity: &quot;Paris&quot;,
}

usa := Country{
    Name: &quot;United Sates of America&quot;,
}</code></pre>
<p>We create two variables of type <span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span> : <span v-highlightjs><code class="go" v-pre style="display: inline">france</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">usa</code></span>.</p>
<p>We can create a new element of type Country, without specifying any fields value :</p>
<pre v-highlightjs><code class="go" v-pre >empty := Country{}</code></pre>
<p>You can also specify certain fields :</p>
<pre v-highlightjs><code class="go" v-pre >usa := Country{
    Name: &quot;United Sates of America&quot;,
}</code></pre>
<p>Other fields will be <strong>equal to the zero value of the type of the field</strong>. Here the value of <span v-highlightjs><code class="go" v-pre style="display: inline">CapitalCity</code></span> will be equal to the zero value of strings : <span v-highlightjs><code class="go" v-pre style="display: inline">""</code></span>.</p>
<div id="without-fields-names" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Without fields names <a href="#without-fields-names"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In the previous example, we write the field name then its value. You can omit the field names :</p>
<pre v-highlightjs><code class="go" v-pre >belgium := Country{
    &quot;Belgium&quot;,
    &quot;Bruxelles&quot;,
}</code></pre>
<p>Here we create a value of type <span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span> and we set the field <span v-highlightjs><code class="go" v-pre style="display: inline">Name</code></span> with “Belgium” and the field <span v-highlightjs><code class="go" v-pre style="display: inline">CapitalCity</code></span> with “Bruxelles”.</p>
<p><u>This syntax has to be used carefully.</u></p>
<div id="field-values-need-to-be-specified-in-the-same-order-as-in-the-type-struct-declaration." class="anchor"></div>
<h4 data-number="7.2.0.1"><span class="header-section-number">7.2.0.1</span> Field values need to be specified in the same order as in the type struct declaration. <a href="#field-values-need-to-be-specified-in-the-same-order-as-in-the-type-struct-declaration."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >type Country struct {
    Name        string
    CapitalCity string
}
japan := Country{
    &quot;Tokyo&quot;,
    &quot;Japan&quot;,
}</code></pre>
<p>This code will compile, but there is an error. The value of Country.Name will be “Tokyo” (not “Japan”)</p>
<div id="you-cannot-skip-a-field" class="anchor"></div>
<h4 data-number="7.2.0.2"><span class="header-section-number">7.2.0.2</span> You cannot skip a field <a href="#you-cannot-skip-a-field"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// WILL NOT COMPILE
china := Country{
    &quot;China&quot;,
}</code></pre>
<p>When you use this syntax, you should initialize all fields.</p>
<div id="do-not-mix-the-two-syntaxes" class="anchor"></div>
<h4 data-number="7.2.0.3"><span class="header-section-number">7.2.0.3</span> Do not mix the two syntaxes! <a href="#do-not-mix-the-two-syntaxes"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<pre v-highlightjs><code class="go" v-pre >// WILL NOT COMPILE
greece := Country{
    Name: &quot;Greece&quot;,
    &quot;Athens&quot;,
}</code></pre>
<div id="how-to-access-a-field-selector-expression" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> How to access a field: selector expression <a href="#how-to-access-a-field-selector-expression"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<figure>
<b-img :src="require('@/assets/images/struct_selector.png')" alt="How to access the value of a field"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">How to access the value of a field</figcaption>
</figure>
<p>To access the value of a field, use the character <span v-highlightjs><code class="go" v-pre style="display: inline">"."</code></span>.</p>
<pre v-highlightjs><code class="go" v-pre >usa := Country{
    Name: &quot;United Sates of America&quot;,
}
usa.CapitalCity = &quot;Washington DC&quot;</code></pre>
<p>You can, of course, also access the value of a specific field :</p>
<pre v-highlightjs><code class="go" v-pre >if usa.Name == &quot;France&quot; {
  fmt.Println(&quot;we have an error !&quot;)
}</code></pre>
<div id="embedded-fields" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Embedded fields <a href="#embedded-fields"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In a type struct, we can add <strong>embedded fields. Embedded</strong> fields are defined <strong>implicitly</strong>.</p>
<pre v-highlightjs><code class="go" v-pre >type Hotel struct {
    Name     string
    Capacity uint8
    Rooms    uint8
    Smoking  bool
    Country
}

type Country struct {
    Name        string
    CapitalCity string
}</code></pre>
<p>In the type struct <span v-highlightjs><code class="go" v-pre style="display: inline">Hotel</code></span> we have an embedded field <span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span>.<span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span> is another type struct.</p>
<p>Embedded fields have no explicit name. The field name is the type name.</p>
<figure>
<b-img :src="require('@/assets/images/embedded_type.png')" alt="Embedded field"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Embedded field</figcaption>
</figure>
<div id="pointer-type" class="anchor"></div>
<h2 data-number="9.1"><span class="header-section-number">9.1</span> Pointer type <a href="#pointer-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In the preceding section, we have seen that we can embed a type into a type struct. We can also embed a pointer type into a type struct :</p>
<pre v-highlightjs><code class="go" v-pre >type Hotel struct {
    Name string
    *Country
}

type Country struct {
    Name        string
    CapitalCity string
}</code></pre>
<p>Here we embed the type <span v-highlightjs><code class="go" v-pre style="display: inline">*Country</code></span> (pointer to an element of type <span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span>). The field name is the type name : <span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >hotel := Hotel{
    Name:    &quot;Hotel super luxe&quot;,
    Country: &amp;Country{Name: &quot;France&quot;},
}
fmt.Println(hotel.Country.Name)</code></pre>
<p>The name of the field is also <span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span>.</p>
<div id="usage-of-embedded-fields" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Usage of embedded fields <a href="#usage-of-embedded-fields"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The embedded field name will be it’s type. Let’s take an example :</p>
<pre v-highlightjs><code class="go" v-pre >// types/embedded/main.go
package main

import &quot;fmt&quot;

type Hotel struct {
    Name string
    Country
}

type Country struct {
    Name        string
    CapitalCity string
}

func main() {
    hotel := Hotel{
        Name:    &quot;Hotel super luxe&quot;,
        Country: Country{Name: &quot;France&quot;},
    }
    fmt.Println(hotel.Country.Name)
}</code></pre>
<p>Here the type struct <span v-highlightjs><code class="go" v-pre style="display: inline">Hotel</code></span> has two fields :</p>
<ul>
<li><p>One explicit field : <span v-highlightjs><code class="go" v-pre style="display: inline">Name</code></span> (of type string)</p></li>
<li><p>And an implicit, embedded field : <span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span></p></li>
</ul>
<p>The name of the embedded field is its type name.</p>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Test Yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="11.1"><span class="header-section-number">11.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Give an example of an array type literal.</p></li>
<li><p>What are the differences between basic types and composite types?</p></li>
<li><p>In a program, you find the following code : <span v-highlightjs><code class="go" v-pre style="display: inline">type Switch bool</code></span>. What is the type name? What is the underlying type?</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span> is a composite type. True or False?</p></li>
<li><p>What is the name of an embedded field of type T ? of type *T?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="11.2"><span class="header-section-number">11.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Give an example of an array type literal.</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">[123]uint64</code></span></li>
</ol></li>
<li><p>What are the differences between basic types and composite types?</p>
<ol type="1">
<li><p>A basic type is predeclared in Go. To use it, you do not have to declare it.</p></li>
<li><p>A composite type is <strong>not</strong> predeclared, you can declare it by using a type literal</p></li>
</ol></li>
<li><p>In a program, you find the following code : <span v-highlightjs><code class="go" v-pre style="display: inline">type Switch bool</code></span>. What is the type name? What is the underlying type?</p>
<ol type="1">
<li><p>Type name is <span v-highlightjs><code class="go" v-pre style="display: inline">Switch</code></span></p></li>
<li><p>The underlying type is <span v-highlightjs><code class="go" v-pre style="display: inline">bool</code></span></p></li>
</ol></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span> is a composite type. True or False?</p>
<ol type="1">
<li><p>False. uint8 is a predeclared simple type.</p></li>
<li><p>It is not composite; it is not composed with other types.<span v-highlightjs><code class="go" v-pre style="display: inline">map[uint8]string</code></span> is a composite type.</p></li>
</ol></li>
<li><p>What is the name of an embedded field of type T ? of type *T?</p>
<ol type="1">
<li>T</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>A type is a set of values with operations and methods specific to those values.</p>
<ul>
<li>We will cover operations and methods in a dedicated chapter.</li>
</ul></li>
<li><p>Go predeclares basic types that you can use to create composite types</p></li>
<li><p>Composite types are constructed with type literals</p></li>
<li><p>Composite types are:</p>
<ul>
<li>array, struct, pointer, function, interface, slice, map, and channel types</li>
</ul></li>
<li><p>Type structs allow you to group data together with fields. Each field of a struct has a type and a name (an identifier)</p></li>
<li><p>We can specify fields of a struct <strong>explicitly</strong> or <strong>implicitly</strong>.</p></li>
<li><p>Implicitly: you embed a type into the struct type, the field is then called an “Embedded Fields”</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type Country struct {
    Name        string
    CapitalCity string
}

type Hotel struct {
    Name     string
    Country
}</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Name</code></span> is a field specified <strong>explicitly</strong></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Country</code></span> is a type struct. It is also a field of the type struct <span v-highlightjs><code class="go" v-pre style="display: inline">Hotel</code></span> , it’s an <strong>embedded field</strong></p>
<ul>
<li>To select a value from a type struct variable, you can use a selector expression, with the character <span v-highlightjs><code class="go" v-pre style="display: inline">"."</code></span>.</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >hotel := Hotel{
    Name:    &quot;Gopher team hotel&quot;,
    Country: Country{
        Name: &quot;France&quot;,
        CapitalCity: &quot;Paris&quot;,
    },
}

log.Println(hotel.Name)
log.Println(hotel.Country.CapitalCity)</code></pre>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>Go Specification https://golang.org/ref/spec#Types<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap13Types"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap12PackageInitialization'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Package Initialization</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap14Methods'}">
									<p><u><small>Next</small></u></p>
									<p><small>Methods</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Types - Practical Go Lessons"
const description = "A type determines a set of values together with operations and methods specific to those values. How to create types in Go ? How to use basic types ?"

export default {
  name: "Chap13Types",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
